
import { Component, Prop, Vue, Ref } from "vue-property-decorator";
import { JnService } from "@/services/jn.service";
import JnProgress from "@/views/common/Progress.vue";
import { Picklist } from "@/models/picklist.model";
import { User } from "@/models/crm/user.model";
import { Purchase } from "@/models/crm/purchase.model";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Mail } from "@/models/mail.model";
import { EmailTemplates } from "@/models/enums.model";
import { StripeCheckout } from "@vue-stripe/vue-stripe";
import { Service } from "@/models/crm/service.model";
import MetaInfo from "vue-meta";
import { AdminCookie } from "@/models/enums.model";

@Component({
  components: {
    JnProgress,
    ValidationObserver,
    ValidationProvider,
    StripeCheckout,
  },
  metaInfo(this: JnAbbonamentiAdmin): MetaInfo {
    return { title: "JuraNews - Abbonamenti" };
  },
})
export default class JnAbbonamentiAdmin extends Vue {
  @Prop({ required: false })
  result?: string;

  // The Purchase Order id
  @Prop({ required: false })
  id?: string;

  @Ref() readonly checkoutRef!: StripeCheckout;

  admin = false;

  firstName = "";
  lastName = "";
  email = "";
  password = "";
  passwordProgress = 0;
  passwordConfirm = "";
  passwordConfirmProgress = 0;
  profession = new Picklist({ value: "", label: "" });
  professionPicklist: Picklist[] = [];
  firstContact = new Picklist({ value: "", label: "" });
  firstContactPicklist: Picklist[] = [];
  codiceFiscale = "";
  partitaIva = "";
  codiceDestinatario = "";
  pec = "";
  address = "";
  cap = "";
  citta = "";
  provincia = new Picklist({ value: "", label: "" });
  provincePicklist: Picklist[] = [];
  mobileNumber = "";
  regExPassword = /((?=.*\d)|(?=.*\W+))(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/;

  showPassword = false;
  showPasswordConfirm = false;
  alert = false;
  alertMessage = "";
  alertTimeout = 6000;
  loading = false;
  success = false;

  showTrial = true;
  show1Anno = false;
  show2Anni = false;
  show3Anni = false;
  serviceTrial = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service1Mese = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service1Anno = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  service2Anni = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );

  service = new Service(
    "",
    "",
    "",
    0,
    "",
    "",
    Number(process.env.VUE_APP_PRODUCT_ID)
  );
  stripePK = process.env.VUE_APP_STRIPE_PK;
  serviceItems: any[] = [];

  user!: User | undefined;
  userExists = false;
  readyForFinalizeSubscription = false;

  get progressPassword(): number {
    this.passwordProgress = this.progress(this.password, this.passwordProgress);
    return this.passwordProgress;
  }

  get colorPassword() {
    if (this.passwordProgress < 80) {
      return "error";
    } else if (this.passwordProgress >= 80 && this.passwordProgress < 100) {
      return "warning";
    } else {
      return "success";
    }
  }

  get progressPasswordConfirm(): number {
    this.passwordConfirmProgress = this.progress(
      this.passwordConfirm,
      this.passwordConfirmProgress
    );
    return this.passwordConfirmProgress;
  }

  async loadUser() {
    if (this.email) {
      this.user = await JnService.getCrmUser(this.email, undefined);
      if (!this.user) {
        this.alertMessage = "L'utente '" + this.email + "' non esiste!";
        this.alert = true;
        this.userExists = false;
      } else {
        const po = await JnService.getActivePurchaseOrder(this.user.id);
        this.updateWithExistingUser(this.user);
        if (po) {
          const service = await JnService.getService(po.serviceId);
          this.alertMessage =
            "L'utente ha già un abbonamento di tipo: " + service.name;
          this.alert = true;
          this.userExists = false;
          this.user = undefined;
        } else {
          this.userExists = true;
          this.readyForFinalizeSubscription = true;
        }
      }
    } else {
      this.alertMessage = "Indicare una email";
      this.alert = true;
    }
  }

  updateWithExistingUser(user: User) {
    this.clearForm();
    this.firstName = user.firstName;
    this.lastName = user.lastName;
    this.email = user.email;
    this.profession = this.getPickList(
      this.professionPicklist,
      user.profession
    )!;
    this.firstContact = this.getPickList(
      this.firstContactPicklist,
      user.firstContact
    )!;
    this.codiceFiscale = user.codiceFiscale;
    this.partitaIva = user.partitaIva;
    this.codiceDestinatario = user.codiceDestinatario;
    this.pec = user.emailPec;
    this.address = user.address;
    this.cap = user.cap;
    this.citta = user.city;
    this.provincia = this.getPickList(this.provincePicklist, user.country)!;
    this.mobileNumber = user.mobileNumber;
  }

  progress(password: string, passwordProgress: number) {
    passwordProgress = Math.min(80, password.length * 10);
    if (this.regExPassword.test(password)) {
      passwordProgress = passwordProgress + 20;
    }
    return passwordProgress;
  }

  subscribe(service: Service) {
    this.service = service;
    const serviceItem = {
      price: String(service.stripePriceID),
      quantity: 1,
    };
    this.serviceItems.push(serviceItem);
  }

  get colorPasswordConfirm() {
    if (!this.passwordMatching) {
      return "error";
    } else {
      return "success";
    }
  }

  async submit() {
    /**
     * Verifica che l'utente non sia già registrato e, se no, lo registra in Postgres e come Contatto in vTiger
     */
    if (!this.readyForFinalizeSubscription) {
      this.loading = true;
      const checkUserExists = await JnService.getCrmUser(this.email, undefined);
      if (checkUserExists && !this.user) {
        this.user = checkUserExists;
        this.userExists = true;
        const po = await JnService.getActivePurchaseOrder(checkUserExists.id);
        this.updateWithExistingUser(checkUserExists);
        if (!po) {
          this.alertMessage = "Utente già esistente: dati aggiornati da CRM.";
          this.loading = false;
          this.alert = true;
          this.readyForFinalizeSubscription = true;
          this.userExists = true;
        } else {
          const service = await JnService.getService(po.serviceId);
          this.alertMessage =
            "Utente già esistente con abbonamento attivo di tipo: " +
            service.name;
          this.loading = false;
          this.alert = true;
          this.userExists = false;
        }
      } else {
        this.loading = false;
        this.userExists = false;
      }

      if (!checkUserExists) {
        this.user = new User(
          this.email.toLowerCase(),
          this.email.toLowerCase(),
          this.email.toLowerCase(),
          this.firstName,
          this.lastName,
          this.profession.value,
          this.firstContact.value,
          this.mobileNumber,
          this.citta,
          "IT",
          this.address,
          this.cap,
          this.provincia.value,
          this.password,
          this.codiceFiscale,
          "",
          "",
          "",
          false
        );
        this.loading = true;
        const res = await JnService.registerUser(this.user);
        if (res.status == 201) {
          await JnService.sendMail(
            new Mail({
              to: this.email,
              template: this.admin
                ? EmailTemplates.ATTIVAZIONE_PASSWORD
                : EmailTemplates.ATTIVAZIONE,
              locals: {
                firstName: this.user.firstName,
                email: this.user.email,
                activationLink:
                  process.env.VUE_APP_JURANEWS_JN_SERVICES_URL +
                  "/user/activate/" +
                  this.user.email,
                password: this.password,
                username: this.user.email,
              },
              attachments: [],
            })
          );
          await JnService.sendMail(
            new Mail({
              to: process.env.VUE_APP_JURANEWS_MAIL!,
              template: EmailTemplates.REGISTRAZIONE,
              locals: {
                firstName: this.user.firstName,
                lastName: this.user.lastName,
                email: process.env.VUE_APP_JURANEWS_MAIL,
                username: this.user.email,
              },
              attachments: [],
            })
          );
          this.readyForFinalizeSubscription = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.alert = true;
          this.alertMessage = res.data;
        }
      }
    }

    if (this.readyForFinalizeSubscription) {
      this.loading = false;
      console.log("Abbonamenti.submit(user): " + JSON.stringify(this.user));
      /**
       * Procede alla creazione del Purchase Order
       */
      const purchase = new Purchase(
        this.service.name,
        this.service.serviceId,
        "",
        "IT",
        this.address,
        this.cap,
        this.provincia ? this.provincia.value : "",
        this.citta,
        this.user,
        this.service,
        undefined,
        "Approved",
        Number(process.env.VUE_APP_MAX_NUM_SESSIONS)
      );

      // console.log(JSON.stringify(purchase));
      /**
       * Sottoscrive il servizio in modalità non ancora attiva (non risulta pagato)
       * Lo stato del Purchase Order in vTiger è "Approved"
       */
      console.log("Abbonamenti.submit(purchase): " + JSON.stringify(purchase));
      let res = await JnService.purchase(purchase);
      console.log("Abbonamenti.submit(resPurchase): " + JSON.stringify(res));

      if (res && res.data.code == 500) {
        this.loading = false;
        this.alert = true;
        this.alertMessage = "Errore in fase di sottoscrizione abbonamento.";
      } else if (res && res.code != 201) {
        this.loading = false;
        this.alert = true;
        this.alertMessage = res.data;
      } else {
        res = await JnService.changeStatusPurchaseOrder(
          res.data.id,
          "Received Shipment"
        );
        console.log(
          "submit(changeStatusPurchaseOrder): " + JSON.stringify(res)
        );
        if (res && res.code != 200) {
          this.loading = false;
          this.alert = true;
          this.alertMessage = res.data;
        }
        this.success = true;
        this.loading = false;
      }
      this.loading = false;
    }
  }

  get passwordMatching(): boolean {
    return (
      this.password == this.passwordConfirm &&
      this.regExPassword.test(this.password)
    );
  }

  get passwordFormatOk() {
    return this.regExPassword.test(this.password) && this.password.length >= 8;
  }

  get passwordOk(): boolean {
    return (
      this.userExists ||
      (!this.userExists && this.passwordMatching && this.passwordFormatOk)
    );
  }

  getPickList(picklist: Picklist[], value: string) {
    for (const p of picklist) {
      if (p.value == value) {
        return p;
      }
    }
  }

  async created() {
    this.loading = true;
    this.professionPicklist = await JnService.getPicklist("Contacts", "title");
    this.firstContactPicklist = await JnService.getPicklist(
      "Contacts",
      "leadsource"
    );
    this.provincePicklist = await JnService.getProvince();
    this.serviceTrial = await JnService.getService(
      process.env.VUE_APP_VTIGER_TRIAL_SERVICE_ID!
    );

    this.service1Mese = await JnService.getService(
      process.env.VUE_APP_VTIGER_1MESE_SERVICE_ID!
    );
    this.service1Anno = await JnService.getService(
      process.env.VUE_APP_VTIGER_1ANNO_SERVICE_ID!
    );
    this.service2Anni = await JnService.getService(
      process.env.VUE_APP_VTIGER_2ANNI_SERVICE_ID!
    );
    this.loading = false;
    this.admin = this.$cookies.get(AdminCookie) === "true";
  }

  cancel() {
    this.serviceItems = [];
    this.service = new Service(
      "",
      "",
      "",
      0,
      "",
      "",
      Number(process.env.VUE_APP_PRODUCT_ID)
    );
    this.user = undefined;
    this.userExists = false;
  }

  clearForm() {
    this.firstName = "";
    this.lastName = "";
    this.email = "";
    this.password = "";
    this.passwordProgress = 0;
    this.passwordConfirm = "";
    this.passwordConfirmProgress = 0;
    this.profession = new Picklist({ value: "", label: "" });
    this.firstContact = new Picklist({ value: "", label: "" });
    this.codiceFiscale = "";
    this.partitaIva = "";
    this.codiceDestinatario = "";
    this.pec = "";
    this.address = "";
    this.cap = "";
    this.citta = "";
    this.provincia = new Picklist({ value: "", label: "" });
    this.mobileNumber = "";
  }
}
